<template>
  <BoosterHeader />
  <div class="body-color">
    <div class="container">
      <div class="row">
        <div class="col sidebar">
          <div class="d-none">
            <BoosterNav />
          </div>
        </div>
        <div class="body-dimensions">
          <div class="main-content">
            <router-view />
          </div>
          <div class="adviser-hub-footer">
            <BoosterFooter />
          </div>
        </div>
        <!-- Extra col on the other side to "balance out" the sidebar col, might not be needed -->
        <div class="col"></div>
      </div>
    </div>
  </div>
  <div>
    <PopupBox
      v-model:showPopupBox="popupState.showPopupBox"
      :content="popupState.content"
      :title="popupState.title"
      :okay="popupState.okay"
      :secondary-text="popupState.secondaryText"
      @primary-btn-action="popupState.primaryActionBtn" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { popupStore } from "@/stores/PopupStore";
import { mapState } from 'pinia';
import BoosterHeader from "@/components/booster-header/BoosterHeader.vue";
import BoosterNav from "@/components/booster-nav/BoosterNav.vue";
import BoosterFooter from "@/components/booster-footer/BoosterFooter.vue";
import PopupBox from '@/components/popup-box/PopupBox.vue';

export default defineComponent({
  name: "AdviserHubLayout",
  components: {
    BoosterHeader,
    BoosterNav,
    BoosterFooter,
    PopupBox
  },
  computed: {
    ...mapState(popupStore, ['popupState']),
  }
});
</script>

<style lang="scss" scoped>
@import "@/scss/booster-styles.scss";

.body-dimensions {
  min-height: calc(100vh - 3.125rem);
  width: 62.25rem;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1; 
}

.sidebar {
  background-color: $booster-background-gray;
}

.body-color {
  background-color: $booster-background-gray;
}
</style>
