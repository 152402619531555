import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { createAuth0 } from "@auth0/auth0-vue";
import ToastService from "primevue/toastservice";

const pinia = createPinia();
const authService = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN ?? "",
  clientId: process.env.VUE_APP_AUTH0_CLIENTID ?? "",
  authorizationParams: {
    redirect_uri: window.location.origin + process.env.BASE_URL,
    audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  },
});

createApp(App)
  .use(router)
  .use(pinia)
  .use(PrimeVue)
  .use(ToastService)
  .use(authService)
  .mount("#app");

// Sets the web browser title
document.title = "Adviser Hub";
